import React from 'react';
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
 import queryString from 'query-string'


import axios from 'axios';
import ReactGA from 'react-ga';

import logo from './logo.svg';
import './App.css';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'

// Set up Google Analytics tracking code
const googleAnalyticsUA_ID = (process.env.REACT_APP_MY_CUSTOM_ENV === 'development') ? 'UA-190257444-1' : 'UA-178035845-1' ;
ReactGA.initialize(googleAnalyticsUA_ID);

// Count the view of the page
ReactGA.pageview(window.location.pathname + window.location.search);


function getCategoryQueryParam() {
  let query = queryString.parse(window.location.search)
  let category = query.category;

  // Set a default if there is no param
  // console.log(category)
  if (!category) {
    category = 'JLPT N5';
  }

  return category;
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      puzzle: [],
      words: [],
      wordList: [],
      activeCoordinateSet: [],
      found: [],
      categories: ['JLPT N5', 'JLPT N4', 'JLPT N3', 'JLPT N2', 'JLPT N1', 'Business', 'Culture', 'Diplomacy', 'Economics', 'Education', 'Health', 'International Relations', 'Judiciary', 'Labor', 'Mass Media', 'Military', 'Natural Disasters', 'Politics', 'Primary Industries', 'Secondary Industries', 'Science', 'Sports', 'Technology', 'Transportation', 'Travel'],
      activeCategory: getCategoryQueryParam(),
      loading: false,
      gameCompleted: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getPuzzle();
  }

  getEndpoint() {

    let endpoint = process.env.REACT_APP_ENDPOINT || '/puzzle/';

    if (this.state.activeCategory) {
      endpoint += '?category=' + this.state.activeCategory;
    }

    return endpoint;
  }

  getPuzzle() {
    const endpoint = this.getEndpoint();
    // console.log(endpoint)
    this.setState({loading: true});
    axios
      .get(endpoint)
      .then(res => {
        // console.log(res.data[0]);
        // console.log(res);
        this.createPuzzle(res.data);
        this.setState({loading:false});
      })
      .catch(err => {
        // console.log(err);
        // console.log(JSON.stringify(err))
      });
  }

  addCoordinateToActiveSet(coordinate) {
    let newCoordinates = this.state.activeCoordinateSet;
    newCoordinates.push(coordinate);
    this.setState({activeCoordinateSet: newCoordinates});
  }

  checkMatch() {
    this.isMatch();
  }

  processFoundWord(word) {
    // add the word to the found state property
    // list property
    let found = this.state.found;
    found.push(word);
    this.setState({found:found});

    // Remove it from the word list
    const unfoundWords = this.state.words.filter(w => w['word'] !== word['word']);
    // console.log(unfoundWords);
    this.setState({words:unfoundWords});

    // Notify Google Analytics that a word was found
    ReactGA.ga('send', 'event', 'Puzzle', 'word found', this.state.activeCategory);

    this.setState({activeCoordinateSet: []});
  }

  isMatch() {
    // When the second coordinate is clicked
    // if (this.state.activeCoordinateSet.length !== 2) {
    //   return false;
    // }
    // Loop through all words
    const wordsToFind = this.state.words;
    for (let i = 0; i < wordsToFind.length; i++) {
      let word = wordsToFind[i];
      // Access the first coordinate and last coordinate of each word
      let letters = word['map'];
      let firstCoordinate = [letters[0]['x'], letters[0]['y']]; 
      let lastCoordinate = [letters[letters.length-1]['x'], letters[letters.length-1]['y']];

      // Check one-letter words first
      // If this was the first click
      if (this.state.activeCoordinateSet.length === 1){
        // console.log('here')
        // If this is a one letter word
        if (JSON.stringify(firstCoordinate) === JSON.stringify(lastCoordinate)) {
          // console.log('herehere')
          // If the click equals the one letter word
          if (JSON.stringify(firstCoordinate) === JSON.stringify(this.state.activeCoordinateSet[0])) {
            // console.log('hereherehere');
            this.processFoundWord(word);
          } else {
            continue;
          }
        }
      }

      // Only two-letter words plus from here on out
      if (this.state.activeCoordinateSet.length !== 2) {
        continue;
      }

      // console.log(JSON.stringify([firstCoordinate, lastCoordinate]));
      // console.log('active:' + JSON.stringify(this.state.activeCoordinateSet));


      // Check if it matches and the reverse matches the active coordinates
      if (JSON.stringify(this.state.activeCoordinateSet) === JSON.stringify([firstCoordinate, lastCoordinate]) ||
         JSON.stringify(this.state.activeCoordinateSet) === JSON.stringify([lastCoordinate, firstCoordinate])) {
        
        // If here, the word was found

        this.processFoundWord(word);


        // console.log(this.state.found);
        // console.log(this.state.words);

      }

    }

    if (this.state.activeCoordinateSet.length === 2) {
      this.setState({activeCoordinateSet: []});
    }
  }

  checkGameCompleted() {
    if (this.state.found.length === this.state.wordList.length) {
      this.setState({gameCompleted: true});

      // Send notification to Google Analytics that the puzzle was completed
      ReactGA.ga('send', 'event', 'Puzzle', 'completed', this.state.activeCategory);
    }
  }

  handleClick(e, coordinate) {
    // console.log(e.target.innerHTML);
    // console.log(coordinate);
    this.addCoordinateToActiveSet(coordinate);
    // console.log(this.state.activeCoordinateSet);
    // console.log(this.state.words);
    this.checkMatch();
    this.checkGameCompleted();
    // console.log(this.state.words);
  }

  // Handle when the user changes the Category dropdown
  handleChange(e) {
    this.setState({activeCategory: e.target.value}, () => {
      this.getPuzzle();
    });
    // console.log(e.target.value);
    // this.getPuzzle();
  }

  createPuzzle(response) {
    let puzzle = [];
    let puzzleString = response.puzzle;
    let puzzleCharCounter = 0;

    for (let x = 0; x < parseInt(response.height); x++ ) {
      puzzle[x] = [];
      for (let y = 0; y < parseInt(response.width); y++ ) {
        puzzle[x][y] = puzzleString.charAt(puzzleCharCounter);
        puzzleCharCounter++;
      }
    }

    this.setState({
      width: parseInt(response.width),
      height: parseInt(response.height),
      words: response.words,
      wordList: response.words,
      puzzle: puzzle,
      activeCoordinateSet: [],
      found: [],
      gameCompleted: false,
    });

    // Set the url with the category query param
    let query = '?' + queryString.stringify({category:this.state.activeCategory});
    // window.location.search = query;
    window.history.pushState(null, null, query)


    // console.log('width'+response.width);
    // console.log('height'+response.height);
    // console.log(this.state.words);

    // Notify Google Analytics that a puzzle was loaded
    ReactGA.ga('send', 'event', 'Puzzle', 'loaded', this.state.activeCategory);

  }

  render() {

    // console.log("game completed"+this.state.gameCompleted);

    if (this.state.loading) {
      return(
        <div className="container">
          <div className="row text-center">
            <div className="col-sm-12">
              <div>Loading Puzzle...</div>
              <Loader
                       type="Oval"
                       color="#00BFFF"
                       height={100}
                       width={100}
                       timeout={12000} //12 secs
              />
            </div>
          </div>
        </div>
      );
    }

    let columns = [];
    let wordLis = [];
    this.state.wordList.forEach((word, i) => {

      let classes = '';
      this.state.found.forEach(w => {
        w['map'].forEach(letter => {
          if (w['word'] === word['word']) {
            classes += 'testcss '
          }
        });
      });

      wordLis.push(<WordLi
        key={i}
        word={word['word']}
        meaning={word['meaning']}
        reading={word['reading']}
        classes={classes}
      />);

      // Split words into two columns
      // if ((i + 1) % Math.floor(this.state.wordList.length/2) === 0 ||
      //     (i + 1) === this.state.wordList.length ) {
      //   columns.push(<div className="col-sm-3" key={i}>{wordLis}</div>);
      //   wordLis = [];
      // }


    });

    let board = [];
    let buttons = [];
    let puzzleCharCounter = 1;

    for (let x = 0; x < this.state.height; x++ ) {
      for (let y = 0; y < this.state.width; y++ ) {
        let letter = this.state.puzzle[x][y];

        let foundClasses = '';
        this.state.found.forEach(word => {
          word['map'].forEach(letter => {
            if (letter['y'] === x && letter['x'] === y) {
              foundClasses += 'testcss '
            }
          });
        });

        if ( this.state.activeCoordinateSet[0] &&
          this.state.activeCoordinateSet[0][0] === y &&
          this.state.activeCoordinateSet[0][1] === x) {
              foundClasses += 'selected ';
           }

        buttons.push(<Square 
          key={puzzleCharCounter.toString()} 
          onClick={this.handleClick} 
          letter={letter}
          coordinate={[y, x]}
          classes={foundClasses}
        />);

        if (puzzleCharCounter === (this.state.height*this.state.width) || 
            puzzleCharCounter % parseInt(this.state.width) === 0) {
          board.push(<div className="board-row" key={puzzleCharCounter.toString()}>{buttons}</div>);
          buttons = [];
        }

        puzzleCharCounter++;
      }
    }

    return (
    <div>
      <div className="row text-xs-center">
          <div className="col-lg-7 col-xl-6 text-center">
            <div className="board">
              {board}
            </div>
          </div>
          <div className="col-lg-5 col-xl-5">
            <div className="dropdown-category">
              Choose category: &nbsp;&nbsp;
              <Dropdown 
                options={this.state.categories} 
                handleChange={this.handleChange} 
                selectedValue={this.state.activeCategory}
              />
            </div>
            <div className="wordlist">
              <table className="table table-striped">
                <thead>
                  <tr><th>Japanese</th><th>Meaning</th></tr>
                </thead>
                <tbody>
                  {wordLis}
                </tbody>
              </table>
            </div>
          </div>
        </div>
    </div>
    );
  }
}

function Square(props) {
  return (
    <button coordinate={props.coordinate} className={"square " + props.classes} onClick={(e, coordinate) => props.onClick(e, props.coordinate)}>
      {props.letter}
    </button>
  );
}

function WordLi(props) {
  return (
    <tr className={props.classes}><td className="reading">{props.reading}<div className="word">{props.word}</div></td><td className="align-middle meaning">{props.meaning}</td></tr>
  );
}

function Dropdown(props) {

  const options = [];
  props.options.forEach((option, i) => {
    options.push(<option key={i} value={option}>{option}</option>);
  });

  return (
    <select value={props.selectedValue} onChange={props.handleChange}>
      {options}
    </select>
  );
}

export default App;
